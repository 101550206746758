import React from "react";
import PropTypes from "prop-types";
import './discover.css'

const Discover = (props) => {
    return(
        <div className="discover thq-section-padding">
            <div className="discover-width thq-section-max-width>">
                <div className="discover-container01">
                    <h1 classname="discover-heading thq-heading-2">{props.heading}</h1>
                    <p className="discover-text01 thq-body-small">{props.about}</p>
                </div>
                <div className="discover-container02">
                    <img className="discover-img" alt="Image" src="./dis1.png"/>
                    <img className="discover-img" alt="Image" src="./dis2.png" />
                    <img className="discover-img" alt="Image" src="./dis3.png" />
                </div>
            </div>
        </div>
    )
}

Discover.defaultProps = {
    heading: 'What You will Discover in this one to one session?',
    about: 'In a one-to-one NLP session, you’ll explore and understand the root causes of limiting beliefs and mental blocks that hold you back. Discover how to reframe negative thought patterns, build emotional resilience, and take control of your inner dialogue. These sessions provide you with practical tools to enhance communication, set and achieve meaningful goals, and break free from unproductive habits. You’ll gain clarity on personal challenges, learn to manage stress, and increase self-confidence. NLP techniques will help you foster better relationships, stay motivated, and approach life with a positive mindset. Ultimately, you’ll leave with a customized action plan designed to unlock your full potential and create lasting success both personally and professionally.',
}

Discover.PropTypes = {
    heading: PropTypes.string,
    about: PropTypes.string,
}

export default Discover