import React from 'react'

import { Helmet } from 'react-helmet'

import Navbar81 from '../components/navbar81'
import Head2 from '../components/head2'
import Hero8 from '../components/hero8'
import Stats2 from '../components/stats2'
import Team1 from '../components/team1'
import Footer1 from '../components/footer1'
import './about.css'

const About = (props) => {
  return (
    <div className="about-container">
      <Helmet>
        <title>About - Amit World Academy</title>
        <meta property="og:title" content="About - Amit World Academy" />
      </Helmet>
      <Navbar81 rootClassName="navbar81-root-class-name"></Navbar81>
      <Head2 rootClassName="head2-root-class-name"></Head2>
      <Hero8></Hero8>
      <Stats2 image1Src="https://images.unsplash.com/photo-1434626881859-194d67b2b86f?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDN8fHN0YXR8ZW58MHx8fHwxNzIwNjc5Njk3fDA&amp;ixlib=rb-4.0.3&amp;w=1400"></Stats2>
      <Team1
        content2="Our team is a dedicated group of experts, passionate about education, innovation, and empowering learners with valuable skills."
        member1="Amit Kumar Jha"
        member1Src="/aks.png"
        member1Job="Business Catalyst / Life Coach"
        member2="Mitali"
        member2Src="/mis.png"
        member2Job="Software Guru"
        member3="Neeta"
        member3Src="/ns.png"
        member3Job="Life Coach"
        member4="Manisha"
        member4Src="/ms.png"
        member4Job="Fashion Guru"
        member5="Shiv Kumar"
        member5Src="/shs.png"
        member5Job="Marketing Head"
        member6="DR Pant"
        member6Src="/ds.png"
        member6Job="Tech. Officer"
        member7="Satwik Choudhary"
        member7Src="/scs.jpg"
        member7Job="Language Guru"
        member8="Saket"
        member8Src="/ss.png"
        member8Job="Jyotishacharya"
        member9="Akash"
        member9Src="/as.png"
        member9Job="AutoCAD Expert"
        member10="Sumala"
        member10Src="/sumala1.jpg"
        member10Job="Coordinator"
        member11="Shivani"
        member11Src="/shivani.png"
        member11Job="Makeup & Skincare Expert"
        member12="Nikita Sharma"
        member12Src="/nikita.png"
        member12Job="Language Expert"
      ></Team1>
      <Footer1 rootClassName="footer1-root-class-name2"></Footer1>
    </div>
  )
}

export default About
