/**import React from 'react'

import PropTypes from 'prop-types'

import './content-list23.css'

const ContentList23 = (props) => {
  return (
    <div className="content-list23-container thq-section-padding">
      <div className="content-list23-max-width thq-flex-column thq-section-max-width">
        <div className="content-list23-content thq-flex-column">
          <ul>
            <li className="content-list23-li thq-flex-column list-item">
              <h2 className="content-list23-heading1 thq-heading-2">
                {props.heading1}
              </h2>
              <p className="thq-body-small">{props.content11}</p>
              <p>{props.text31}</p>
              <ul className="content-list23-ul1 thq-flex-column">
                <li className="list-item">
                  <h3 className="thq-heading-3">{props.heading2}</h3>
                  <p>{props.content2}</p>
                  <p className="thq-body-small">{props.content22}</p>
                </li>
                <li className="list-item">
                  <h3 className="thq-heading-3">{props.heading3}</h3>
                  <p className="thq-body-small">{props.content3}</p>
                  <p className="thq-body-small">{props.content32}</p>
                  <p className="thq-body-small">{props.content33}</p>
                  <p className="thq-body-small">{props.content34}</p>
                </li>
                <li className="list-item">
                  <h3 className="thq-heading-3">{props.heading4}</h3>
                  <p className="thq-body-small">{props.content4}</p>
                  <p className="thq-body-small">{props.content42}</p>
                </li>
                <li className="list-item">
                  <h3 className="thq-heading-3">{props.heading5}</h3>
                  <p className="thq-body-small">{props.content5}</p>
                </li>
                <li className="list-item">
                  <h3 className="thq-heading-3">{props.heading6}</h3>
                  <p className="thq-body-small">{props.content6}</p>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div className="content-list23-content1 thq-body-small content-list23-content1 thq-flex-column">
          <ul>
            <li className="content-list23-li06 thq-flex-column list-item">
              <h2 className="content-list23-heading7 thq-heading-2">
                {props.heading7}
              </h2>
              <p className="content-list23-content7 thq-body-small">
                {props.content7}
              </p>
              <ul className="content-list23-ul3 thq-flex-column">
                <li className="list-item">
                  <h3 className="thq-heading-3">{props.heading8}</h3>
                  <p className="thq-body-small">{props.content2}</p>
                </li>
                <li className="list-item">
                  <h3 className="thq-heading-3">{props.heading9}</h3>
                  <p className="thq-body-small">{props.content9}</p>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div className="thq-body-small content-list23-content2 thq-flex-column">
          <ul>
            <li className="content-list23-li09 list-item">
              <h2 className="content-list23-text6 thq-heading-2">
                {props.heading10}
              </h2>
              <p className="content-list23-text7 thq-body-small">
                {props.text1}
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

ContentList23.defaultProps = {
  heading1: 'Privacy statement',
  heading2: 'Types of data we collect',
  content42:
    'We may also release information when its release is appropriate to comply with the law, enforce our site policies, or protect ours or others’ rights, property or safety.',
  heading9: 'Products and Services',
  content7:
    'Lorem ipsum dolor sit amet. Vel dolores illum est aperiam quis nam voluptatem quia et omnis autem qui dolore ullam sed fugiat cumque! Qui accusamus assumenda et molestias eius et error sunt. Id recusandae nostrum ea officiis voluptatem in nisi consequatur sed quia tenetur sit alias molestias qui illum soluta.  Est nesciunt perferendis eum sint rerum 33 cupiditate dolorem id corrupti laboriosam ut debitis veniam ut ipsam fugit vel sunt consequatur. Et nobis quasi et cumque adipisci aut molestiae eligendi quo inventore dicta ea suscipit sequi sed veritatis nemo.',
  heading7: 'Terms of service',
  content3:
    'To provide and maintain our services, including registering you for online courses and administering these courses.',
  heading4: 'Sharing your data with 3rd parties',
  heading3: 'How we use your data',
  heading: 'Heading',
  text2: 'Text',
  content2:
    'Personal Information: We collect personal information that you provide to us voluntarily. This includes your name, email address, contact details, and any other information you provide when you register, participate in a course, or contact us for support.',
  text: 'Text',
  content34:
    'For security purposes, to prevent fraud, and to ensure the integrity of our Site.',
  content6:
    'We use cookies, pixels, and other technologies (collectively, "cookies") to recognize your browser or device, learn more about your interests, and provide you with essential features and services.',
  heading8: 'General Terms and Conditions',
  content4:
    'We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information. This does not include trusted third parties who assist us in operating our Site, conducting our business, or serving our users, so long as those parties agree to keep this information confidential.',
  content5:
    'Lorem ipsum dolor sit amet. Ut cumque cupiditate eos perferendis tempora et ullam quis qui fugiat necessitatibus qui quia dolorem 33 earum reprehenderit eum rerum blanditiis. Et vitae distinctio 33 magni ratione ut odit rerum est nihil error et minus dolor quo harum fugiat. Eos quam assumenda id fugit optio aut magni sunt!  Ut iure aliquam vel velit modi sit voluptatibus atque ut corporis sint sit omnis enim a pariatur officiis aut nulla voluptate. In facere incidunt aut sapiente maxime qui quibusdam facilis non officia consectetur sit laboriosam libero aut cupiditate possimus ut sunt reiciendis. Et repudiandae magnam aut quaerat ipsam aut repellat laboriosam. Ab facilis deleniti ut voluptas molestiae sed omnis maiores ut aliquid culpa vel nesciunt saepe.  Aut placeat aspernatur aut alias nihil vel neque recusandae et corrupti accusantium ab quod temporibus ut nulla eaque et magnam nemo. Ad sunt minus rem earum delectus hic officia iste qui sunt quos non officiis illo vel doloribus perspiciatis. Ab soluta eius sed quidem dolores rem necessitatibus minus 33 minus commodi. Nam repudiandae libero non laboriosam voluptate et saepe fuga vel repudiandae pariatur aut assumenda illo.',
  content33:
    'To communicate with you, including sending you updates about courses you are enrolled in, responding to your inquiries, and providing customer support.',
  content32: 'To improve and personalize your experience on our Site.',
  heading5: 'Campaign tracking',
  heading6: 'Cookies',
  text1:
    'The information provided on the Amit World Academy website (https://www.amitworldacademy.com) is for general informational purposes only. While we strive to keep the information up-to-date and accurate, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability with respect to the website, the e-platform, or the information, products, services, or related graphics contained on the website for any purpose. Any reliance you place on such information is therefore strictly at your own risk. In no event will we be liable for any loss or damage including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the use of this website and e-platform. Through this website, you may be able to link to other websites which are not under the control of Amit World Academy. We have no control over the nature, content, and availability of those sites.',
  content9:
    'Lorem ipsum dolor sit amet. Est vitae blanditiis ab aliquam tempore aut ipsam iusto in sunt repellat ex voluptatum inventore ab facilis galisum ea consequatur consequuntur. Ab voluptas voluptatem eum consequatur aspernatur non laboriosam atque est labore asperiores a neque quos.  Ea nemo modi hic dicta saepe et veritatis maiores At praesentium aliquid. Sed dolores architecto non doloribus quia eos consectetur commodi non tenetur vitae est neque omnis.  Non perspiciatis velit At aliquam rerum ut officiis ipsa id minima eius ut sapiente nobis et nemo neque. Aut maiores tempora in officiis sunt eum voluptatem tenetur sit iste reprehenderit ea nisi dolor. Ea impedit omnis ad internos autem ut esse sunt ad saepe maiores vel perferendis veritatis. Ex magni fugiat ut reprehenderit laudantium sit galisum ipsam eos tempora doloribus sed accusantium nobis eum praesentium quod.',
  text31:
    'Welcome to Amit World Academy! We respect your privacy and are committed to protecting your personal information. This privacy policy explains how we collect, use, and share information about you when you visit and interact with https://amitworldacademy.com (hereafter referred to as “the Site”).',
  content22:
    'Usage and Technical Data: We automatically collect information about your interaction with our Site, such as IP addresses, browser type, Internet service provider, referring/exit pages, operating system, date/time stamps, and related data.',
  content11: 'Effective Date: 10 July 2024',
  heading10: 'Disclaimer',
  textareaPlaceholder: 'placeholder',
}

ContentList23.propTypes = {
  heading1: PropTypes.string,
  heading2: PropTypes.string,
  content42: PropTypes.string,
  heading9: PropTypes.string,
  content7: PropTypes.string,
  heading7: PropTypes.string,
  content3: PropTypes.string,
  heading4: PropTypes.string,
  heading3: PropTypes.string,
  heading: PropTypes.string,
  text2: PropTypes.string,
  content2: PropTypes.string,
  text: PropTypes.string,
  content34: PropTypes.string,
  content6: PropTypes.string,
  heading8: PropTypes.string,
  content4: PropTypes.string,
  content5: PropTypes.string,
  content33: PropTypes.string,
  content32: PropTypes.string,
  heading5: PropTypes.string,
  heading6: PropTypes.string,
  text1: PropTypes.string,
  content9: PropTypes.string,
  text31: PropTypes.string,
  content22: PropTypes.string,
  content11: PropTypes.string,
  heading10: PropTypes.string,
  textareaPlaceholder: PropTypes.string,
}

export default ContentList23
**/

import React from 'react';
import PropTypes from 'prop-types';

import './content-list23.css';

const ContentList23 = (props) => {
  return (
    <div className="content-list23-container thq-section-padding">
      <div className="content-list23-max-width thq-flex-column thq-section-max-width">
        <div className="content-list23-content thq-flex-column">
          <ul>
            <li className="content-list23-li thq-flex-column list-item">
              <h2 className="content-list23-heading1 thq-heading-2">
                {props.heading1}
              </h2>
              <p className="thq-body-small">{props.content11}</p>
              <p className="thq-body-small">
                Welcome to Amit World Academy! We respect your privacy and are committed to protecting your personal information. This privacy policy explains how we collect, use, and share information about you when you visit and interact with{' '}
                <a className="color" href="https://amitworldacademy.com" target="_blank" rel="noopener noreferrer">
                  https://amitworldacademy.com
                </a>.
              </p>
              <ul className="content-list23-ul1 thq-flex-column">
                <li className="list-item">
                  <h3 className="thq-heading-3">{props.heading2}</h3>
                  <p className="thq-body-small">• {props.content2}</p>
                  <p className="thq-body-small">• {props.content22}</p>
                </li>
                <li className="list-item">
                  <h3 className="thq-heading-3">{props.heading3}</h3>
                  <p className="thq-body-small">• {props.content3}</p>
                  <p className="thq-body-small">• {props.content32}</p>
                  <p className="thq-body-small">• {props.content33}</p>
                  <p className="thq-body-small">• {props.content34}</p>
                </li>
                <li className="list-item">
                  <h3 className="thq-heading-3">{props.heading4}</h3>
                  <p className="thq-body-small">• {props.content4}</p>
                  <p className="thq-body-small">• {props.content42}</p>
                </li>
                <li className="list-item">
                  <h3 className="thq-heading-3">{props.heading5}</h3>
                  <p className="thq-body-small">{props.content5}</p>
                </li>
                <li className="list-item">
                  <h3 className="thq-heading-3">{props.heading6}</h3>
                  <p className="thq-body-small">{props.content6}</p>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div className="content-list23-content1 thq-body-small content-list23-content1 thq-flex-column">
          <ul>
            <li className="content-list23-li06 thq-flex-column list-item">
              <h2 className="content-list23-heading7 thq-heading-2">
                {props.heading7}
              </h2>
              <p className="content-list23-content7 thq-body-small">
                By using this platform, you agree to adhere to the terms outlined, including but not limited to proper conduct, intellectual property compliance, and payment obligations. For support, contact us at{' '}
                <a className="color" href="mailto:amitworldofficial@gmail.com">amitworldofficial@gmail.com</a>.
              </p>
              <ul className="content-list23-ul3 thq-flex-column">
                <li className="list-item">
                  <h3 className="thq-heading-3">{props.heading8}</h3>
                  <p className="thq-body-small">• {props.content211}</p>
                  <p className="thq-body-small">• {props.content212}</p>
                </li>
                <li className="list-item">
                  <h3 className="thq-heading-3">{props.heading9}</h3>
                  <p className="thq-body-small">• {props.content9}</p>
                  <p className="thq-body-small">• {props.content91}</p>
                </li>
                <li className="list-item">
                  <h3 className="thq-heading-3">{props.heading11}</h3>
                  <p className="thq-body-small">• {props.content110}</p>
                  <p className="thq-body-small">• {props.content111}</p>
                  <p className="thq-body-small">• {props.content112}</p>
                  <p className="thq-body-small">• {props.content113}</p>
                </li>
                <li className="list-item">
                  <h3 className="thq-heading-3">{props.heading12}</h3>
                  <p className="thq-body-small">Refunds are subject to our <a className="color" href="https://docs.google.com/document/d/1MFXINT5hXUpl-v452h-FxKl4VlMeB2xwIXl-2tyT3LU/edit?usp=sharing">Return Policy</a>. Please refer to it for eligibility and processing details.</p>
                </li>
                <li className="list-item">
                  <h3 className="thq-heading-3">{props.heading13}</h3>
                  <p className="thq-body-small">{props.content131}</p>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div className="thq-body-small content-list23-content2 thq-flex-column">
          <ul>
            <li className="content-list23-li09 list-item">
              <h2 className="content-list23-text6 thq-heading-2">
                {props.heading10}
              </h2>
              <p className="content-list23-text7 thq-body-small">
                The information on the <a className="color" href="https://www.amitworldacademy.com" target="_blank" rel="noopener noreferrer">
                Amit World Academy website
                </a> is for general informational purposes only. While we strive to ensure the accuracy and reliability of the information, we make no guarantees about its completeness, suitability, or availability.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

ContentList23.defaultProps = {
  heading1: 'Privacy Statement',
  content11: 'Effective Date: 10 July 2024',
  text31: 'Welcome to Amit World Academy! We respect your privacy and are committed to protecting your personal information. This privacy policy explains how we collect, use, and share information about you when you visit and interact with https://amitworldacademy.com.',
  heading2: 'Types of Data We Collect',
  content2: 'Personal Information: We collect personal information you voluntarily provide, including your name, email address, contact details, and more. Usage and Technical Data is also collected automatically.',
  content22: 'This includes IP addresses, browser types, and system data.',
  heading3: 'How We Use Your Data',
  content3: 'To provide and maintain our services.',
  content32: 'To improve and personalize your experience.',
  content33: 'To communicate with you and provide support.',
  content34: 'For security purposes and fraud prevention.',
  heading4: 'Sharing Your Data with 3rd Parties',
  content4: 'We do not sell or trade your data, except with trusted parties aiding in operations.',
  content42: 'Data may be shared to comply with legal requirements or enforce policies.',
  heading5: 'Campaign Tracking',
  content5: 'Campaign tracking helps us understand marketing performance. We use tools to analyze user interactions, enhance experience, and measure the success of promotional efforts.',
  heading6: 'Cookies',
  content6: 'We use cookies and similar technologies to enhance user experience and analyze website traffic. Cookies help us understand user preferences and optimize Site functionality. Users can manage or disable cookies through their browser settings, but doing so may affect Site performance.',
  heading7: 'Terms of Service',
  heading8: 'General Terms and Conditions',
  content211: 'Personal Information: By using our Site, you agree to provide accurate personal information during registration, participation in courses, or support inquiries.',
  content212: 'Products and Services: All courses and materials provided are intended solely for personal and non-commercial use. Redistribution or sharing of purchased content is strictly prohibited.',
  heading9: 'Course Access and Usage',
  content9: 'Course access is granted for the duration specified. Lifetime access may depend on platform availability.',
  content91: 'Users are prohibited from sharing login credentials or copying course content.',
  heading11: 'Payment Policies',
  content110: 'Accepted Payment Methods: Payments can be made using credit/debit cards, Paytm, Phonepe, G-Pay, or other available payment gateways.',
  content111: 'Subscription Plans: Subscriptions renew automatically unless canceled. Cancelation applies to the next billing cycle; no partial refunds are provided for the current cycle.',
  content112: 'Failed Payments: Users with failed payments will be notified, and access to services may be restricted until payment is resolved.',
  content113: 'Disputes: For payment disputes, contact us through amitworldofficial@gmail.com within 30 days of the transaction.',
  heading12: 'Refund Policy',
  content121: '',
  heading13: 'Intellectual Property',
  content131: 'All content on Amit World Academy, including course materials, is the intellectual property of the academy. Unauthorized reproduction or distribution is prohibited.',
  heading10: 'Disclaimer',
};

ContentList23.propTypes = {
  heading1: PropTypes.string,
  content11: PropTypes.string,
  text31: PropTypes.string,
  heading2: PropTypes.string,
  content2: PropTypes.string,
  content22: PropTypes.string,
  heading3: PropTypes.string,
  content3: PropTypes.string,
  content32: PropTypes.string,
  content33: PropTypes.string,
  content34: PropTypes.string,
  heading4: PropTypes.string,
  content4: PropTypes.string,
  content42: PropTypes.string,
  heading5: PropTypes.string,
  content5: PropTypes.string,
  heading6: PropTypes.string,
  content6: PropTypes.string,
  heading7: PropTypes.string,
  content7: PropTypes.string,
  heading8: PropTypes.string,
  heading9: PropTypes.string,
  content9: PropTypes.string,
  heading10: PropTypes.string,
  text1: PropTypes.string,
};

export default ContentList23;
