import React from 'react'

import { Helmet } from 'react-helmet'

import Navbar81 from '../components/navbar81'
import Hero from '../components/hero'
import Testimonialnpl from '../components/testimonialnpl'
import Message from '../components/message'
import Footer1 from '../components/footer1'
import FAQ  from '../components/faq11'
import Bonus from '../components/bonus'
import Discover from '../components/discover'
import Lpart3 from '../components/lpart3'
import Intro from '../components/intro'
import Lpfea from '../components/lpfea'
import './lp.css'

const lp = (props) => {
    return (
        <div className="lp-container">
            <Helmet>
                <title>NeuroSuccess Bootcamp: Master Your Mind, Master Your Life</title>
                <meta property="og:title" content="Amit World Academy" />
            </Helmet>
            <Navbar81></Navbar81>
            <Hero></Hero>
            <Intro
            link = "https://forms.gle/S7wdCrPpVKjGLwrd9"
            ></Intro>
            <Lpart3
            link = "https://forms.gle/S7wdCrPpVKjGLwrd9"
            ></Lpart3>
            <Discover></Discover>
            <Bonus
            link = "https://forms.gle/S7wdCrPpVKjGLwrd9"
            ></Bonus>
            <Message></Message>
            <Testimonialnpl></Testimonialnpl>
            <Lpfea
            ImageAlt ="Courses Offerings Image"
            Title= "Breakthrough Stories of 'NLP with NS'"
            ImageSrc= "author1.jpeg"
            Description= "I had joined NLP with a purpose and after completing the course, I can confidently say it has given me more than I had expected. We always look outside for the solution to our problems but never want to look within or try to understand ourselves. NLP helped me in understanding what type of person I am and why I behave a certain way, and the most amazing part is through the tools and techniques we can change our thoughts and behaviors and become a much better person with a lot more clarity in life. Thanking Neeta ma’am from the bottom of my heart for answering all my queries and mentoring me throughout the session. She is kind-hearted and always wears a smile on her face which makes the students comfortable to learn from her. I’ll be forever grateful to her."
            session= "With thanks and gratitude,"
            leader= "Lily Pradhan"
            ></Lpfea>
            <FAQ></FAQ>
            <Footer1></Footer1>
        </div>
    )
}
export default lp