import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import './intro.css';

const Intro = (props) => {
    const calculateTimeLeft = () => {
        const difference = +new Date("2024-12-15") - +new Date();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                Days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                Hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                Minutes: Math.floor((difference / 1000 / 60) % 60),
                Seconds: Math.floor((difference / 1000) % 60)
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearTimeout(timer);
    }, [timeLeft]);

    const timerComponents = Object.keys(timeLeft).map(interval => {
        if (!timeLeft[interval]) {
            return null;
        }

        return (
            <div key={interval} className="timer-container">
                <div className="timer-interval">{interval}</div>
                <div className={`timer-circle ${interval}`}>
                    {timeLeft[interval]}
                </div>
            </div>
        );
    });

    return (
        <div id="intro" className="intro thq-section-padding">
            <div className="intro-width thq-section-max width">
                <div className="intro-container00">
                    <h2 className="intro-text thq-heading-2">{props.Event}</h2>
                    <p className="intro-text01 thq-body-large">{props.content}</p>
                </div>
                <div className="intro-container02">
                    <img alt="Image" src={props.img1} className="intro-img1" />
                    <img alt="Image" src={props.img2} className="intro-img2" />
                </div>
                <div className="intro-timer">
                    {timerComponents.length ? timerComponents : <span>Remaining</span>}
                </div>
                <a href={props.link} target="_blank" rel="noopener noreferrer">
                    <button className="intro-button">
                        {props.action}
                    </button>
                </a>
                <img alt="Image" src="/intro3.png" className="intro-img3" />
                <span className="intro-text intro-padding">Language of Session: English & Hindi</span>
            </div>
        </div>
    );
};

Intro.defaultProps = {
    Event: 'One on One Session to Overcome Overthinking, Self-Doubt Procrastination & Mobile Distraction!',
    content: 'Discover the transformative 3-Step Clarity & Confidence Formula, trusted by high achievers to conquer mental blocks, eliminate self-doubt, and regain momentum in life.',
    action: 'Request One on One Session',
    img1: './999.png',
    img2: './free.png',
    link: 'https://forms.gle/S7wdCrPpVKjGLwrd9',
};

Intro.propTypes = {
    Event: PropTypes.string,
    content: PropTypes.string,
    action: PropTypes.string,
    img1: PropTypes.string,
    img2: PropTypes.string,
    link: PropTypes.string,
};

export default Intro;
