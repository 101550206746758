import React from 'react'

import { Helmet } from 'react-helmet'

import Navbar81 from '../components/navbar81'
import Hero from '../components/hero'
import Testimonialnpl from '../components/testimonialnpl'
import Message from '../components/message'
import Footer1 from '../components/footer1'
import FAQ  from '../components/faq11'
import Bonus from '../components/bonus'
import Discover from '../components/discover'
import Lpart3 from '../components/lpart3'
import Intro from '../components/intro'
import './lps.css'

const lps = (props) => {
    return (
        <div className="lp-container">
            <Helmet>
                <title>NeuroSuccess: Master Your Mind, Master Your Life</title>
                <meta property="og:title" content="Amit World Academy" />
            </Helmet>
            <Navbar81></Navbar81>
            <Hero
            heading1= "7 Day Challenge"
            heading2= "To Overcome Overthinking, Self-doubt & Procrastination."
            slides={[ {
                    type: 'image',
                    src: './main3.png',
                    alt: 'First Image'
                }, 
                {
                    type: 'video',
                    src: './main1.mp4',
                    alt: 'First Video'
                }, ]}
            ></Hero>
            <Intro
            action = "Join Us in New Journey"
            link = "https://forms.gle/jzmChPSxvKrXDCY66"
            ></Intro>
            <Lpart3
            action = "Join Us in New Journey"
            link = "https://forms.gle/jzmChPSxvKrXDCY66"
            ></Lpart3>
            <Discover></Discover>
            <Bonus 
            action = "Join Us in New Journey"
            link = "https://forms.gle/jzmChPSxvKrXDCY66"
            ></Bonus>
            <Message></Message>
            <Testimonialnpl></Testimonialnpl>
            <FAQ></FAQ>
            <Footer1></Footer1>
        </div>
    )
}
export default lps