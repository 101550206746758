import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import './faq11.css'

const FAQ11 = (props) => {
  const [faq4Visible, setFaq4Visible] = useState(false)
  const [faq1Visible, setFaq1Visible] = useState(false)
  const [faq3Visible, setFaq3Visible] = useState(false)
  const [faq2Visible, setFaq2Visible] = useState(false)
  const [faq5Visible, setFaq5Visible] = useState(false)
  const [faq6Visible, setFaq6Visible] = useState(false)
  const [faq7Visible, setFaq7Visible] = useState(false)
  const [faq8Visible, setFaq8Visible] = useState(false)
  return (
    <div className="faq11-faq8 thq-section-padding">
      <div className="faq11-max-width thq-section-max-width">
        <div className="thq-flex-column thq-section-max-width faq11-container">
          <div className="faq11-section-title">
            <div className="faq11-content">
              <h2 className="faq11-text thq-heading-2">{props.heading1}</h2>
              <p className="faq11-text0 thq-body-large">{props.content1}</p>
            </div>
          </div>
          <div className="faq11-list">
            <div className="faq11-faq1">
              <div
                onClick={() => setFaq1Visible(!faq1Visible)}
                className="faq11-trigger"
              >
                <p className="faq11-question thq-body-large">
                  {props.faq1Question}
                </p>
                <div className="faq11-icons-container">
                  {!faq1Visible && (
                    <div>
                      <svg viewBox="0 0 1024 1024" className="faq11-icon">
                        <path d="M213.333 554.667h256v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256h256c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-256v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v256h-256c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                      </svg>
                    </div>
                  )}
                  {faq1Visible && (
                    <div>
                      <svg viewBox="0 0 1024 1024" className="faq11-icon02">
                        <path d="M213.333 554.667h597.333c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-597.333c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                      </svg>
                    </div>
                  )}
                </div>
              </div>
              {faq1Visible && (
                <div className="faq-text faq11-container03">
                  <span className="thq-body-small">NLP is a psychological approach that involves understanding how language, behavior, and thought patterns can influence your life. It aims to enhance communication, personal development, and achieve goals by rewiring your thought processes.
                  </span>
                </div>
              )}
            </div>
            <div className="faq11-faq2">
              <div
                onClick={() => setFaq2Visible(!faq2Visible)}
                className="faq11-trigger1"
              >
                <p className="faq11-question thq-body-large">
                  {props.faq2Question}
                </p>
                <div className="faq11-icons-container1">
                  {!faq2Visible && (
                    <div>
                      <svg viewBox="0 0 1024 1024" className="faq11-icon04">
                        <path d="M213.333 554.667h256v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256h256c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-256v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v256h-256c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                      </svg>
                    </div>
                  )}
                  {faq2Visible && (
                    <div>
                      <svg viewBox="0 0 1024 1024" className="faq11-icon06">
                        <path d="M213.333 554.667h597.333c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-597.333c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                      </svg>
                    </div>
                  )}
                </div>
              </div>
              {faq2Visible && (
                <div className="faq-text faq11-container06">
                  <span className="thq-body-small">Anyone who wants to improve aspects of their personal or professional life can benefit. Whether you're struggling with anxiety, self-confidence, or want to enhance your communication skills, NLP can be a valuable tool.</span>
                </div>
              )}
            </div>
            <div className="faq11-faq3">
              <div
                onClick={() => setFaq3Visible(!faq3Visible)}
                className="faq11-trigger2"
              >
                <p className="faq11-question thq-body-large">
                  {props.faq3Question}
                </p>
                <div className="faq11-icons-container2">
                  {!faq3Visible && (
                    <div>
                      <svg viewBox="0 0 1024 1024" className="faq11-icon08">
                        <path d="M213.333 554.667h256v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256h256c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-256v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v256h-256c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                      </svg>
                    </div>
                  )}
                  {faq3Visible && (
                    <div>
                      <svg viewBox="0 0 1024 1024" className="faq11-icon10">
                        <path d="M213.333 554.667h597.333c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-597.333c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                      </svg>
                    </div>
                  )}
                </div>
              </div>
              {faq3Visible && (
                <div className="faq-text faq11-container09">
                  <span className="thq-body-small">A typical one-to-one session lasts between 25 to 30 minutes. However, the duration may vary depending on individual needs and the goals of the session.</span>
                </div>
              )}
            </div>
            <div className="faq11-faq4">
              <div
                onClick={() => setFaq4Visible(!faq4Visible)}
                className="faq11-trigger3"
              >
                <p className="faq11-question thq-body-large">
                  {props.faq4Question}
                </p>
                <div className="faq11-icons-container3">
                  {!faq4Visible && (
                    <div>
                      <svg viewBox="0 0 1024 1024" className="faq11-icon12">
                        <path d="M213.333 554.667h256v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256h256c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-256v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v256h-256c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                      </svg>
                    </div>
                  )}
                  {faq4Visible && (
                    <div>
                      <svg viewBox="0 0 1024 1024" className="faq11-icon14">
                        <path d="M213.333 554.667h597.333c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-597.333c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                      </svg>
                    </div>
                  )}
                </div>
              </div>
              {faq4Visible && (
                <div className="faq-text faq11-container12">
                  <span className="thq-body-small">
                  NLP can help with a variety of challenges, including:
                    <ol>
                      <li>Reducing anxiety and stress</li>
                      <li>Overcoming fears and phobias</li>
                      <li>Boosting confidence and self-esteem</li>
                      <li>Improving communication and relationships</li>
                      <li>Setting and achieving personal or professional goals</li>
                    </ol>
                  </span>
                </div>
              )}
            </div>
            <div className="faq11-faq5">
              <div
                onClick={() => setFaq5Visible(!faq5Visible)}
                className="faq11-trigger4"
              >
                <p className="faq11-question thq-body-large">
                  {props.faq5Question}
                </p>
                <div className="faq11-icons-container4">
                  {!faq5Visible && (
                    <div>
                      <svg viewBox="0 0 1024 1024" className="faq11-icon16">
                        <path d="M213.333 554.667h256v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256h256c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-256v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v256h-256c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                      </svg>
                    </div>
                  )}
                  {faq5Visible && (
                    <div>
                      <svg viewBox="0 0 1024 1024" className="faq11-icon18">
                        <path d="M213.333 554.667h597.333c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-597.333c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                      </svg>
                    </div>
                  )}
                </div>
              </div>
              {faq5Visible && (
                <div className="faq-text faq11-container15">
                  <span className="thq-body-small">NLP works by identifying and changing patterns of thought and behavior. It uses techniques like visualization, language modeling, and anchoring to help shift your mindset and improve emotional responses.</span>
                </div>
              )}
            </div>
            <div className="faq11-faq5">
              <div
                onClick={() => setFaq6Visible(!faq6Visible)}
                className="faq11-trigger4"
              >
                <p className="faq11-question thq-body-large">
                  {props.faq6Question}
                </p>
                <div className="faq11-icons-container4">
                  {!faq6Visible && (
                    <div>
                      <svg viewBox="0 0 1024 1024" className="faq11-icon16">
                        <path d="M213.333 554.667h256v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256h256c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-256v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v256h-256c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                      </svg>
                    </div>
                  )}
                  {faq6Visible && (
                    <div>
                      <svg viewBox="0 0 1024 1024" className="faq11-icon18">
                        <path d="M213.333 554.667h597.333c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-597.333c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                      </svg>
                    </div>
                  )}
                </div>
              </div>
              {faq6Visible && (
                <div className="faq-text faq11-container15">
                  <span className="thq-body-small">Most clients feel a sense of clarity, relief, and empowerment after a session. The techniques can help you let go of limiting beliefs, feel more positive, and gain a clearer direction in life.</span>
                </div>
              )}
            </div>
            <div className="faq11-faq5">
              <div
                onClick={() => setFaq7Visible(!faq5Visible)}
                className="faq11-trigger4"
              >
                <p className="faq11-question thq-body-large">
                  {props.faq7Question}
                </p>
                <div className="faq11-icons-container4">
                  {!faq7Visible && (
                    <div>
                      <svg viewBox="0 0 1024 1024" className="faq11-icon16">
                        <path d="M213.333 554.667h256v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256h256c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-256v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v256h-256c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                      </svg>
                    </div>
                  )}
                  {faq7Visible && (
                    <div>
                      <svg viewBox="0 0 1024 1024" className="faq11-icon18">
                        <path d="M213.333 554.667h597.333c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-597.333c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                      </svg>
                    </div>
                  )}
                </div>
              </div>
              {faq7Visible && (
                <div className="faq-text  faq11-container15">
                  <span className="thq-body-small">You can book a session by filling the form below or you can visit our website www.amitworldacademy.com, and we'll get in touch to arrange a time that suits you best.</span>
                </div>
              )}
            </div>
            <div className="faq11-faq5">
              <div
                onClick={() => setFaq8Visible(!faq8Visible)}
                className="faq11-trigger4"
              >
                <p className="faq11-question thq-body-large">
                  {props.faq8Question}
                </p>
                <div className="faq11-icons-container4">
                  {!faq8Visible && (
                    <div>
                      <svg viewBox="0 0 1024 1024" className="faq11-icon16">
                        <path d="M213.333 554.667h256v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256h256c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-256v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v256h-256c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                      </svg>
                    </div>
                  )}
                  {faq8Visible && (
                    <div>
                      <svg viewBox="0 0 1024 1024" className="faq11-icon18">
                        <path d="M213.333 554.667h597.333c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-597.333c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                      </svg>
                    </div>
                  )}
                </div>
              </div>
              {faq8Visible && (
                <div className="faq11-container15">
                  <span className="thq-body-small">Yes, all sessions are strictly confidential. Your privacy and personal growth are of the utmost importance to us.</span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="faq11-content1 thq-flex-column">
          <div className="faq11-content2">
            <h2 className="faq11-text7 thq-heading-2">{props.heading2}</h2>
            <p className="faq11-text0 thq-body-large">{props.content2}</p>
          </div>
          <Link to="/contact" className="faq11-button thq-button-filled">
            <span className="thq-body-small">{props.action}</span>
          </Link>
        </div>
      </div>
    </div>
  )
}

FAQ11.defaultProps = {
  content1: 'Have questions? Our FAQ covers common queries about our services, process, and what to expect. Get clear answers here',
  heading1: 'Frequently Asked Questions',
  heading2: 'Still have a question?',
  content2: 'If you still need help, don’t hesitate to reach out!',
  action: 'Contact',
  faq1Question: 'What is Neuro-Linguistic Programming (NLP)?',
  faq2Question: 'Who can benefit from an NLP one-to-one session?',
  faq3Question: 'How long does a typical session last?',
  faq4Question: 'What kind of problems can NLP help me with?',
  faq5Question: 'How does NLP work?',
  faq6Question: 'What can I expect to feel after an NLP session?',
  faq7Question: 'How do I schedule an NLP one-to-one session?',
  faq8Question: 'Are the sessions confidential?',
}

FAQ11.propTypes = {
  content1: PropTypes.string,
  heading1: PropTypes.string,
  heading2: PropTypes.string,
  faq4Question: PropTypes.string,
  content2: PropTypes.string,
  action: PropTypes.string,
  faq1Question: PropTypes.string,
  faq2Question: PropTypes.string,
  faq3Question: PropTypes.string,
  faq5Question: PropTypes.string,
  faq6Question: PropTypes.string,
  faq7Question: PropTypes.string,
  faq8Question: PropTypes.string,
}

export default FAQ11
