/**
import React from "react";
import PropTypes from "prop-types";
import './bonus.css'

const Bonus = (props) => {
    return (
        <div className="bonus thq-section-padding">
            <div className="bonus-width thq-section-max-width">
                <div className="bonus-container01">
                    <h1 className="bonus-heading thq-heading-1">{props.heading}</h1>
                    <p className="bonus-text01 thq-body-large">{props.content}</p>
                </div>
                <div className="bonus-container02">
                    <img alt={props.imgAlt1} src={props.imgSrc1} className = "bonus-img" />
                    <img alt={props.imgAlt2} src={props.imgSrc2} className = "bonus-img" />
                </div>
            </div>
            <a href={props.link} target="_blank" rel="noopener noreferrer">
                <button className="intro-button">
                    {props.action}
                </button>
            </a>
        </div>
    )
}

Bonus.defaultProps = {
    heading: 'Exclusive Bonus Worth ₹1,500 – Yours Free!',
    content: 'Get access to our exclusive bonus, valued at ₹1,500, at absolutely no cost! This special offer is packed with valuable insights and resources designed to enhance your journey. Don’t miss out on this chance to gain extra tools for growth and success—all for free when you join us!',
    imgAlt1: 'Image',
    imgSrc1: './bonus1.png',
    imgAlt2: 'Image',
    imgSrc2: './bonus2.png',
    action: 'Request one on one Session',
    link: 'https://forms.gle/S7wdCrPpVKjGLwrd9',
}

Bonus.PropTypes = {
    heading: PropTypes.string,
    content: PropTypes.string,
    imgAlt1: PropTypes.string,
    imgSrc1: PropTypes.string,
    imgAlt2: PropTypes.string,
    imgSrc2: PropTypes.string,
    action: PropTypes.string,
    link: PropTypes.string,
}

export default Bonus
**/

import React from "react";
import PropTypes from "prop-types";
import './bonus.css'

const Bonus = (props) => {
    return (
        <div className="bonus thq-section-padding">
            <div className="bonus-width thq-section-max-width">
                <div className="bonus-container01">
                    <h1 className="bonus-heading thq-heading-1">{props.heading}</h1>
                    <p className="bonus-text01 thq-body-large">{props.content}</p>
                </div>
                <div className="bonus-container02">
                    <img alt={props.imgAlt1} src={props.imgSrc1} className = "bonus-img" />
                </div>
            </div>
            <a href={props.link} target="_blank" rel="noopener noreferrer">
                <button className="intro-button">
                    {props.action}
                </button>
            </a>
        </div>
    )
}

Bonus.defaultProps = {
    heading: 'Exclusive Bonus Worth ₹599 – Yours Free!',
    content: 'Get access to our exclusive bonus, valued at ₹599, at absolutely no cost! This special offer is packed with valuable insights and resources designed to enhance your journey. Don’t miss out on this chance to gain extra tools for growth and success—all for free when you join us!',
    imgAlt1: 'Image',
    imgSrc1: './bonus21.png',
    action: 'Request one on one Session',
    link: 'https://forms.gle/S7wdCrPpVKjGLwrd9',
}

Bonus.PropTypes = {
    heading: PropTypes.string,
    content: PropTypes.string,
    imgAlt1: PropTypes.string,
    imgSrc1: PropTypes.string,
    imgAlt2: PropTypes.string,
    imgSrc2: PropTypes.string,
    action: PropTypes.string,
    link: PropTypes.string,
}

export default Bonus