import React from 'react'

import PropTypes from 'prop-types'

import './message.css'

const Message = (props) => {
  return(
    <div className = "message thq-section-padding">
      <div className = "message-width thq-flex-row thq-section-max-width">
        <div className = "message-container">
          <h2 className = "message-heading thq-heading-2">{props.heading}</h2>
        </div>
        <div className = "message-container01">
          <div className = "message-container02">
            <img alt={props.imgAlt} src={props.imgSrc} className = "message-image" />
            <strong className = "thq-body-large">{props.name}</strong>
            <span className = "message-text1 thq-body-small">{props.position}</span>
          </div>
          <div className="message-container03">
            <span className = "message-text thq-body-small">{props.content}</span>
            <span className="message-text thq-body-small">{props.content2}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

Message.defaultProps = {
  heading: 'Know Your Coach' ,
  imgSrc: './ns2.png' ,
  imgAlt: 'Image' ,
  name: 'Neeta Sisodiya' ,
  position: 'Mind Catalyst | NLP Artist | Law of Attraction & Visualization Expert' ,
  content: 'With over a decade of experience, Neeta Sisodiya is a transformational coach dedicated to helping individuals unlock their true potential. As a Mind Catalyst and NLP Artist, she has empowered over 20,000 people through her workshops, providing them with tools to master their mindset, visualize success, and attract their deepest desires.',
  content2: 'Neeta’s expertise in the Law of Attraction and Visualization techniques has made her a sought-after guide for those seeking to create lasting change in their personal and professional lives. Whether it’s overcoming limiting beliefs or manifesting dreams into reality, her approach is both practical and deeply inspiring.'
}

Message.PropTypes = {
  heading: PropTypes.string,
  imgSrc: PropTypes.string,
  imgAlt: PropTypes.string,
  name: PropTypes.string,
  position: PropTypes.string,
  content: PropTypes.string,
  content2: PropTypes.string
}

export default Message