import React from 'react'

import PropTypes from 'prop-types'

import './nlp1.css'

const Nlp1 = (props) => {
  return (
    <div
      id="nlp1"
      className={`npl1-layout349 thq-section-padding ${props.rootClassName} `}
    >
      <section id={props.ID} className="npl1-max-width thq-section-max-width">
        <div className="npl1-image-container">
          <img
            alt={props.ImageAlt}
            src={props.ImageSrc}
            className="thq-img-ratio-16-9"
          />
        </div>
        <div className="npl1-content">
          <div className="npl1-section-title">
            <div className="npl1-content1">
              <h2 className="thq-heading-2">{props.Title}</h2>
              <p className="thq-body-large">{props.Description}</p>
              <p className="thq-body-large">{props.session}</p>
              <p className="thq-body-large">{props.leader}</p>
              <h2 className="npl1-text3 thq-heading-2">{props.cost}</h2>
            </div>
            <div className="npl1-actions">
              <a
                href={props.link}
                target="_blank"
                rel="noreferrer noopener"
                className="npl1-button-ready thq-button-filled"
              >
                <span className="thq-body-small">{props.Action1}</span>
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

Nlp1.defaultProps = {
  ID: '1',
  cost: 'INR 4997',
  Action1: 'Enroll Now',
  rootClassName: '',
  ImageAlt: 'Courses Offerings Image',
  Title: 'Diverse Course Offerings',
  ImageSrc: 'https://images.unsplash.com/photo-1695654400723-d643e072c35d?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyMDMzNTcwNXw&ixlib=rb-4.0.3&q=80&w=1080',
  Description: 'One Demo Class For Free',
  session: '12 sessions in total 2 session per weak',
  leader: 'By Neeta Sisodiya',
  link: 'https://www.amitworldacademy.com',
}

Nlp1.propTypes = {
  ID: PropTypes.string,
  cost: PropTypes.string,
  Action1: PropTypes.string,
  rootClassName: PropTypes.string,
  ImageAlt: PropTypes.string,
  Title: PropTypes.string,
  ImageSrc: PropTypes.string,
  Description: PropTypes.string,
  session: PropTypes.string,
  leader: PropTypes.string,
  link: PropTypes.string,
}

export default Nlp1
